/*
@tailwind base;

@tailwind components;
*/

.bg-transparent {
  background-color: transparent
}

.bg-black {
  background-color: #22292F
}

.bg-white {
  background-color: #FFF
}

.bg-grey-200 {
  background-color: #EEEEEE
}

.bg-grey-700 {
  background-color: #616161
}

.bg-red-100 {
  background-color: #FFCDD2
}

.bg-red-700 {
  background-color: #D32F2F
}

.bg-red {
  background-color: #F44336
}

.bg-orange {
  background-color: #FF9800
}

.bg-yellow-50 {
  background-color: #FFFDE7
}

.bg-yellow-100 {
  background-color: #FFF9C4
}

.bg-yellow-700 {
  background-color: #FBC02D
}

.bg-green {
  background-color: #4CAF50
}

.bg-blue {
  background-color: #2196F3
}

.bg-pink {
  background-color: #E91E63
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.border-collapse {
  border-collapse: collapse
}

.border-black {
  border-color: #22292F
}

.border-white {
  border-color: #FFF
}

.border-grey-300 {
  border-color: #E0E0E0
}

.border-grey-400 {
  border-color: #BDBDBD
}

.border-red {
  border-color: #F44336
}

.border-yellow-700 {
  border-color: #FBC02D
}

.rounded-4 {
  border-radius: .4rem
}

.rounded-6 {
  border-radius: .6rem
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-12 {
  border-radius: 1.2rem
}

.rounded-24 {
  border-radius: 2.4rem
}

.rounded-32 {
  border-radius: 3.2rem
}

.rounded-none {
  border-radius: 0
}

.rounded-sm {
  border-radius: .2rem
}

.rounded {
  border-radius: .4rem
}

.rounded-lg {
  border-radius: .8rem
}

.rounded-full {
  border-radius: 9999px
}

.border-solid {
  border-style: solid
}

.border-dotted {
  border-style: dotted
}

.border-0 {
  border-width: 0
}

.border-1 {
  border-width: 1px
}

.border-2 {
  border-width: 2px
}

.border {
  border-width: 1px
}

.border-t-1 {
  border-top-width: 1px
}

.border-r-1 {
  border-right-width: 1px
}

.border-b-1 {
  border-bottom-width: 1px
}

.border-l-1 {
  border-left-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.grid {
  display: grid
}

.table {
  display: table
}

.hidden {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-no-wrap {
  flex-wrap: nowrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.self-start {
  align-self: flex-start
}

.self-center {
  align-self: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-around {
  justify-content: space-around
}

.justify-evenly {
  justify-content: space-evenly
}

.content-center {
  align-content: center
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-grow-0 {
  flex-grow: 0
}

.flex-grow {
  flex-grow: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.font-200 {
  font-weight: 200
}

.font-300 {
  font-weight: 300
}

.font-400 {
  font-weight: 400
}

.font-500 {
  font-weight: 500
}

.font-600 {
  font-weight: 600
}

.font-700 {
  font-weight: 700
}

.font-light {
  font-weight: 300
}

.font-normal {
  font-weight: 400
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.h-2 {
  height: 0.2rem
}

.h-6 {
  height: 0.6rem
}

.h-8 {
  height: 0.8rem
}

.h-16 {
  height: 1.6rem
}

.h-20 {
  height: 2rem
}

.h-24 {
  height: 2.4rem
}

.h-32 {
  height: 3.2rem
}

.h-40 {
  height: 4rem
}

.h-44 {
  height: 4.4rem
}

.h-48 {
  height: 4.8rem
}

.h-52 {
  height: 5.2rem
}

.h-56 {
  height: 5.6rem
}

.h-64 {
  height: 6.4rem
}

.h-72 {
  height: 7.2rem
}

.h-96 {
  height: 9.6rem
}

.h-128 {
  height: 12.8rem
}

.h-136 {
  height: 13.6rem
}

.h-160 {
  height: 16rem
}

.h-200 {
  height: 20rem
}

.h-224 {
  height: 22.4rem
}

.h-256 {
  height: 25.6rem
}

.h-288 {
  height: 28.8rem
}

.h-320 {
  height: 32rem
}

.h-360 {
  height: 36rem
}

.h-400 {
  height: 40rem
}

.h-512 {
  height: 51.2rem
}

.h-auto {
  height: auto
}

.h-full {
  height: 100%
}

.leading-none {
  line-height: 1
}

.leading-tight {
  line-height: 1.25
}

.m-0 {
  margin: 0
}

.m-4 {
  margin: 0.4rem
}

.m-8 {
  margin: 0.8rem
}

.m-10 {
  margin: 1.0rem
}

.m-16 {
  margin: 1.6rem
}

.m-24 {
  margin: 2.4rem
}

.m-32 {
  margin: 3.2rem
}

.-m-4 {
  margin: -0.4rem
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0
}

.mx-0 {
  margin-left: 0;
  margin-right: 0
}

.mx-2 {
  margin-left: 0.2rem;
  margin-right: 0.2rem
}

.my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.my-6 {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem
}

.mx-6 {
  margin-left: 0.6rem;
  margin-right: 0.6rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.my-10 {
  margin-top: 1.0rem;
  margin-bottom: 1.0rem
}

.mx-10 {
  margin-left: 1.0rem;
  margin-right: 1.0rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.my-20 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem
}

.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem
}

.my-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem
}

.my-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem
}

.mx-48 {
  margin-left: 4.8rem;
  margin-right: 4.8rem
}

.mx-52 {
  margin-left: 5.2rem;
  margin-right: 5.2rem
}

.my-128 {
  margin-top: 12.8rem;
  margin-bottom: 12.8rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-mx-2 {
  margin-left: -0.2rem;
  margin-right: -0.2rem
}

.-mx-4 {
  margin-left: -0.4rem;
  margin-right: -0.4rem
}

.-mx-6 {
  margin-left: -0.6rem;
  margin-right: -0.6rem
}

.-mx-8 {
  margin-left: -0.8rem;
  margin-right: -0.8rem
}

.-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

.-mx-16 {
  margin-left: -1.6rem;
  margin-right: -1.6rem
}

.-mx-32 {
  margin-left: -3.2rem;
  margin-right: -3.2rem
}

.mt-0 {
  margin-top: 0
}

.mb-0 {
  margin-bottom: 0
}

.ml-2 {
  margin-left: 0.2rem
}

.mt-4 {
  margin-top: 0.4rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.ml-4 {
  margin-left: 0.4rem
}

.mt-6 {
  margin-top: 0.6rem
}

.mr-6 {
  margin-right: 0.6rem
}

.mb-6 {
  margin-bottom: 0.6rem
}

.ml-6 {
  margin-left: 0.6rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.mt-10 {
  margin-top: 1.0rem
}

.mr-10 {
  margin-right: 1.0rem
}

.mb-10 {
  margin-bottom: 1.0rem
}

.ml-10 {
  margin-left: 1.0rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.ml-16 {
  margin-left: 1.6rem
}

.mt-20 {
  margin-top: 2rem
}

.mr-20 {
  margin-right: 2rem
}

.mb-20 {
  margin-bottom: 2rem
}

.ml-20 {
  margin-left: 2rem
}

.mt-24 {
  margin-top: 2.4rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.ml-24 {
  margin-left: 2.4rem
}

.mb-28 {
  margin-bottom: 2.8rem
}

.ml-28 {
  margin-left: 2.8rem
}

.mt-32 {
  margin-top: 3.2rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.ml-32 {
  margin-left: 3.2rem
}

.mb-36 {
  margin-bottom: 3.6rem
}

.mt-40 {
  margin-top: 4rem
}

.mr-40 {
  margin-right: 4rem
}

.mb-40 {
  margin-bottom: 4rem
}

.ml-40 {
  margin-left: 4rem
}

.mt-44 {
  margin-top: 4.4rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mb-48 {
  margin-bottom: 4.8rem
}

.ml-52 {
  margin-left: 5.2rem
}

.mb-60 {
  margin-bottom: 6rem
}

.ml-60 {
  margin-left: 6rem
}

.mt-64 {
  margin-top: 6.4rem
}

.mb-64 {
  margin-bottom: 6.4rem
}

.mb-80 {
  margin-bottom: 8rem
}

.mt-96 {
  margin-top: 9.6rem
}

.mt-192 {
  margin-top: 19.2rem
}

.-mb-24 {
  margin-bottom: -2.4rem
}

.-mb-28 {
  margin-bottom: -2.8rem
}

.-mt-192 {
  margin-top: -19.2rem
}

[dir='ltr'] .ltr\:ml-4,[dir='ltr'].ltr\:ml-4 {
  margin-left: 0.4rem
}

[dir='ltr'] .ltr\:mr-8,[dir='ltr'].ltr\:mr-8 {
  margin-right: 0.8rem
}

[dir='ltr'] .ltr\:ml-8,[dir='ltr'].ltr\:ml-8 {
  margin-left: 0.8rem
}

[dir='ltr'] .ltr\:mr-16,[dir='ltr'].ltr\:mr-16 {
  margin-right: 1.6rem
}

[dir='ltr'] .ltr\:mr-48,[dir='ltr'].ltr\:mr-48 {
  margin-right: 4.8rem
}

[dir='ltr'] .ltr\:-ml-12,[dir='ltr'].ltr\:-ml-12 {
  margin-left: -1.2rem
}

[dir='rtl'] .rtl\:mr-4,[dir='rtl'].rtl\:mr-4 {
  margin-right: 0.4rem
}

[dir='rtl'] .rtl\:mr-8,[dir='rtl'].rtl\:mr-8 {
  margin-right: 0.8rem
}

[dir='rtl'] .rtl\:ml-8,[dir='rtl'].rtl\:ml-8 {
  margin-left: 0.8rem
}

[dir='rtl'] .rtl\:ml-16,[dir='rtl'].rtl\:ml-16 {
  margin-left: 1.6rem
}

[dir='rtl'] .rtl\:ml-48,[dir='rtl'].rtl\:ml-48 {
  margin-left: 4.8rem
}

[dir='rtl'] .rtl\:-mr-12,[dir='rtl'].rtl\:-mr-12 {
  margin-right: -1.2rem
}

.max-h-320 {
  max-height: 32rem
}

.max-h-400 {
  max-height: 40rem
}

.max-h-640 {
  max-height: 64rem
}

.max-h-full {
  max-height: 100%
}

.max-w-0 {
  max-width: 0
}

.max-w-64 {
  max-width: 6.4rem
}

.max-w-160 {
  max-width: 16rem
}

.max-w-288 {
  max-width: 28.8rem
}

.max-w-320 {
  max-width: 32rem
}

.max-w-384 {
  max-width: 38.4rem
}

.max-w-400 {
  max-width: 40rem
}

.max-w-512 {
  max-width: 51.2rem
}

.max-w-sm {
  max-width: 48rem
}

.max-w-md {
  max-width: 64rem
}

.max-w-lg {
  max-width: 80rem
}

.max-w-xl {
  max-width: 96rem
}

.max-w-2xl {
  max-width: 112rem
}

.max-w-full {
  max-width: 100%
}

.min-h-0 {
  min-height: 0
}

.min-h-40 {
  min-height: 4rem
}

.min-h-44 {
  min-height: 4.4rem
}

.min-h-48 {
  min-height: 4.8rem
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-72 {
  min-height: 7.2rem
}

.min-h-92 {
  min-height: 9.2rem
}

.min-h-96 {
  min-height: 9.6rem
}

.min-h-160 {
  min-height: 16rem
}

.min-h-200 {
  min-height: 20rem
}

.min-h-px {
  min-height: 1px
}

.min-h-full {
  min-height: 100%
}

.min-w-0 {
  min-width: 0
}

.min-w-2 {
  min-width: 0.2rem
}

.min-w-20 {
  min-width: 2rem
}

.min-w-24 {
  min-width: 2.4rem
}

.min-w-32 {
  min-width: 3.2rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-48 {
  min-width: 4.8rem
}

.min-w-64 {
  min-width: 6.4rem
}

.min-w-128 {
  min-width: 12.8rem
}

.min-w-160 {
  min-width: 16rem
}

.min-w-px {
  min-width: 1px
}

.min-w-full {
  min-width: 100%
}

.object-contain {
  object-fit: contain
}

.object-cover {
  object-fit: cover
}

.opacity-25 {
  opacity: 0.25
}

.opacity-50 {
  opacity: 0.5
}

.opacity-75 {
  opacity: 0.75
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-scroll {
  overflow: scroll
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-y-hidden {
  overflow-y: hidden
}

.p-0 {
  padding: 0
}

.p-2 {
  padding: 0.2rem
}

.p-4 {
  padding: 0.4rem
}

.p-6 {
  padding: 0.6rem
}

.p-8 {
  padding: 0.8rem
}

.p-10 {
  padding: 1.0rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-20 {
  padding: 2rem
}

.p-24 {
  padding: 2.4rem
}

.p-32 {
  padding: 3.2rem
}

.p-48 {
  padding: 4.8rem
}

.p-88 {
  padding: 8.8rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem
}

.px-6 {
  padding-left: 0.6rem;
  padding-right: 0.6rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-10 {
  padding-top: 1.0rem;
  padding-bottom: 1.0rem
}

.px-10 {
  padding-left: 1.0rem;
  padding-right: 1.0rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.px-20 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem
}

.px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem
}

.px-40 {
  padding-left: 4rem;
  padding-right: 4rem
}

.px-60 {
  padding-left: 6rem;
  padding-right: 6rem
}

.px-72 {
  padding-left: 7.2rem;
  padding-right: 7.2rem
}

.py-96 {
  padding-top: 9.6rem;
  padding-bottom: 9.6rem
}

.pt-0 {
  padding-top: 0
}

.pb-0 {
  padding-bottom: 0
}

.pr-2 {
  padding-right: 0.2rem
}

.pl-2 {
  padding-left: 0.2rem
}

.pt-4 {
  padding-top: 0.4rem
}

.pr-4 {
  padding-right: 0.4rem
}

.pb-4 {
  padding-bottom: 0.4rem
}

.pl-4 {
  padding-left: 0.4rem
}

.pt-6 {
  padding-top: 0.6rem
}

.pb-6 {
  padding-bottom: 0.6rem
}

.pl-6 {
  padding-left: 0.6rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pr-8 {
  padding-right: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pt-10 {
  padding-top: 1.0rem
}

.pb-10 {
  padding-bottom: 1.0rem
}

.pl-10 {
  padding-left: 1.0rem
}

.pt-12 {
  padding-top: 1.2rem
}

.pr-12 {
  padding-right: 1.2rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pl-12 {
  padding-left: 1.2rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-20 {
  padding-top: 2rem
}

.pl-20 {
  padding-left: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pb-28 {
  padding-bottom: 2.8rem
}

.pt-32 {
  padding-top: 3.2rem
}

.pb-32 {
  padding-bottom: 3.2rem
}

.pl-32 {
  padding-left: 3.2rem
}

.pb-40 {
  padding-bottom: 4rem
}

.pl-40 {
  padding-left: 4rem
}

.pt-48 {
  padding-top: 4.8rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pr-60 {
  padding-right: 6rem
}

.pt-64 {
  padding-top: 6.4rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-64 {
  padding-left: 6.4rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pb-80 {
  padding-bottom: 8rem
}

.pl-80 {
  padding-left: 8rem
}

.pr-92 {
  padding-right: 9.2rem
}

.pb-96 {
  padding-bottom: 9.6rem
}

.pb-224 {
  padding-bottom: 22.4rem
}

[dir='ltr'] .ltr\:pl-4,[dir='ltr'].ltr\:pl-4 {
  padding-left: 0.4rem
}

[dir='ltr'] .ltr\:pl-40,[dir='ltr'].ltr\:pl-40 {
  padding-left: 4rem
}

[dir='ltr'] .ltr\:pr-48,[dir='ltr'].ltr\:pr-48 {
  padding-right: 4.8rem
}

[dir='ltr'] .ltr\:pl-56,[dir='ltr'].ltr\:pl-56 {
  padding-left: 5.6rem
}

[dir='ltr'] .ltr\:pl-72,[dir='ltr'].ltr\:pl-72 {
  padding-left: 7.2rem
}

[dir='rtl'] .rtl\:pr-4,[dir='rtl'].rtl\:pr-4 {
  padding-right: 0.4rem
}

[dir='rtl'] .rtl\:pr-40,[dir='rtl'].rtl\:pr-40 {
  padding-right: 4rem
}

[dir='rtl'] .rtl\:pl-48,[dir='rtl'].rtl\:pl-48 {
  padding-left: 4.8rem
}

[dir='rtl'] .rtl\:pr-56,[dir='rtl'].rtl\:pr-56 {
  padding-right: 5.6rem
}

[dir='rtl'] .rtl\:pr-72,[dir='rtl'].rtl\:pr-72 {
  padding-right: 7.2rem
}

.pointer-events-none {
  pointer-events: none
}

.pointer-events-auto {
  pointer-events: auto
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: -webkit-sticky;
  position: sticky
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.inset-x-0 {
  right: 0;
  left: 0
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

[dir='ltr'] .ltr\:right-0,[dir='ltr'].ltr\:right-0 {
  right: 0
}

[dir='ltr'] .ltr\:left-0,[dir='ltr'].ltr\:left-0 {
  left: 0
}

[dir='rtl'] .rtl\:right-0,[dir='rtl'].rtl\:right-0 {
  right: 0
}

[dir='rtl'] .rtl\:left-0,[dir='rtl'].rtl\:left-0 {
  left: 0
}

.resize {
  resize: both
}

.shadow-1 {
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)
}

.shadow-10 {
  box-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)
}

.shadow-20 {
  box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)
}

.shadow-none {
  box-shadow: none
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-justify {
  text-align: justify
}

.text-black {
  color: #22292F
}

.text-white {
  color: #FFF
}

.text-grey-700 {
  color: #616161
}

.text-gray-800 {
  color: #424242
}

.text-red-300 {
  color: #E57373
}

.text-red-900 {
  color: #B71C1C
}

.text-red {
  color: #F44336
}

.text-orange-300 {
  color: #FFB74D
}

.text-orange {
  color: #FF9800
}

.text-green {
  color: #4CAF50
}

.text-blue {
  color: #2196F3
}

.text-10 {
  font-size: 1rem
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-15 {
  font-size: 1.5rem
}

.text-16 {
  font-size: 1.6rem
}

.text-17 {
  font-size: 1.7rem
}

.text-18 {
  font-size: 1.8rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-32 {
  font-size: 3.2rem
}

.text-36 {
  font-size: 3.6rem
}

.text-40 {
  font-size: 4rem
}

.text-44 {
  font-size: 4.4rem
}

.text-48 {
  font-size: 4.8rem
}

.text-56 {
  font-size: 5.6rem
}

.text-64 {
  font-size: 6.4rem
}

.text-72 {
  font-size: 7.2rem
}

.text-96 {
  font-size: 9.6rem
}

.text-128 {
  font-size: 12.8rem
}

.text-2xl {
  font-size: 2.4rem
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.line-through {
  text-decoration: line-through
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.whitespace-no-wrap {
  white-space: nowrap
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.break-words {
  overflow-wrap: break-word
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-0 {
  width: 0
}

.w-16 {
  width: 1.6rem
}

.w-20 {
  width: 2rem
}

.w-24 {
  width: 2.4rem
}

.w-32 {
  width: 3.2rem
}

.w-40 {
  width: 4rem
}

.w-44 {
  width: 4.4rem
}

.w-48 {
  width: 4.8rem
}

.w-64 {
  width: 6.4rem
}

.w-72 {
  width: 7.2rem
}

.w-80 {
  width: 8rem
}

.w-84 {
  width: 8.4rem
}

.w-96 {
  width: 9.6rem
}

.w-128 {
  width: 12.8rem
}

.w-160 {
  width: 16rem
}

.w-192 {
  width: 19.2rem
}

.w-224 {
  width: 22.4rem
}

.w-256 {
  width: 25.6rem
}

.w-288 {
  width: 28.8rem
}

.w-320 {
  width: 32rem
}

.w-384 {
  width: 38.4rem
}

.w-xs {
  width: 32rem
}

.w-xl {
  width: 96rem
}

.w-px {
  width: 1px
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-2\/3 {
  width: 66.666667%
}

.w-1\/4 {
  width: 25%
}

.w-2\/4 {
  width: 50%
}

.w-3\/4 {
  width: 75%
}

.w-1\/5 {
  width: 20%
}

.w-2\/5 {
  width: 40%
}

.w-3\/5 {
  width: 60%
}

.w-4\/5 {
  width: 80%
}

.w-1\/6 {
  width: 16.666667%
}

.w-2\/6 {
  width: 33.333333%
}

.w-3\/6 {
  width: 50%
}

.w-4\/6 {
  width: 66.666667%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.z-30 {
  z-index: 30
}

.z-40 {
  z-index: 40
}

.z-999 {
  z-index: 999
}

.z-9999 {
  z-index: 9999
}

.gap-4 {
  grid-gap: 0.4rem;
  gap: 0.4rem
}

.gap-6 {
  grid-gap: 0.6rem;
  gap: 0.6rem
}

.gap-8 {
  grid-gap: 0.8rem;
  gap: 0.8rem
}

.gap-10 {
  grid-gap: 1.0rem;
  gap: 1.0rem
}

.gap-12 {
  grid-gap: 1.2rem;
  gap: 1.2rem
}

.gap-16 {
  grid-gap: 1.6rem;
  gap: 1.6rem
}

.gap-20 {
  grid-gap: 2rem;
  gap: 2rem
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

@media (min-width: 380) {

  .xs\:w-full {
    width: 100%
  }
}

@media (min-width: 600px) {

  .sm\:rounded-16 {
    border-radius: 1.6rem
  }

  .sm\:border-1 {
    border-width: 1px
  }

  .sm\:flex {
    display: flex
  }

  .sm\:table-cell {
    display: table-cell
  }

  .sm\:hidden {
    display: none
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:items-center {
    align-items: center
  }

  .sm\:justify-start {
    justify-content: flex-start
  }

  .sm\:justify-between {
    justify-content: space-between
  }

  .sm\:h-56 {
    height: 5.6rem
  }

  .sm\:h-96 {
    height: 9.6rem
  }

  .sm\:h-136 {
    height: 13.6rem
  }

  .sm\:h-160 {
    height: 16rem
  }

  .sm\:h-256 {
    height: 25.6rem
  }

  .sm\:h-288 {
    height: 28.8rem
  }

  .sm\:h-320 {
    height: 32rem
  }

  .sm\:h-360 {
    height: 36rem
  }

  .sm\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  .sm\:mb-0 {
    margin-bottom: 0
  }

  .sm\:mb-12 {
    margin-bottom: 1.2rem
  }

  .sm\:mt-16 {
    margin-top: 1.6rem
  }

  .sm\:mt-32 {
    margin-top: 3.2rem
  }

  .sm\:mt-88 {
    margin-top: 8.8rem
  }

  .sm\:min-h-136 {
    min-height: 13.6rem
  }

  .sm\:min-h-160 {
    min-height: 16rem
  }

  .sm\:p-2 {
    padding: 0.2rem
  }

  .sm\:p-8 {
    padding: 0.8rem
  }

  .sm\:p-10 {
    padding: 1.0rem
  }

  .sm\:p-12 {
    padding: 1.2rem
  }

  .sm\:p-16 {
    padding: 1.6rem
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:p-32 {
    padding: 3.2rem
  }

  .sm\:p-64 {
    padding: 6.4rem
  }

  .sm\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  .sm\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .sm\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .sm\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .sm\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  .sm\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .sm\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  .sm\:pt-0 {
    padding-top: 0
  }

  .sm\:pb-0 {
    padding-bottom: 0
  }

  .sm\:pb-16 {
    padding-bottom: 1.6rem
  }

  .sm\:pb-64 {
    padding-bottom: 6.4rem
  }

  .sm\:pb-80 {
    padding-bottom: 8rem
  }

  [dir='ltr'] .sm\:ltr\:text-left,[dir='ltr'].sm\:ltr\:text-left {
    text-align: left
  }

  [dir='rtl'] .sm\:rtl\:text-right,[dir='rtl'].sm\:rtl\:text-right {
    text-align: right
  }

  .sm\:text-20 {
    font-size: 2rem
  }

  .sm\:text-24 {
    font-size: 2.4rem
  }

  .sm\:text-40 {
    font-size: 4rem
  }

  .sm\:text-56 {
    font-size: 5.6rem
  }

  .sm\:w-48 {
    width: 4.8rem
  }

  .sm\:w-224 {
    width: 22.4rem
  }

  .sm\:w-320 {
    width: 32rem
  }

  .sm\:w-auto {
    width: auto
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:w-1\/3 {
    width: 33.333333%
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: flex
  }

  .md\:hidden {
    display: none
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:items-start {
    align-items: flex-start
  }

  .md\:items-end {
    align-items: flex-end
  }

  .md\:items-center {
    align-items: center
  }

  .md\:justify-start {
    justify-content: flex-start
  }

  .md\:flex-1 {
    flex: 1 1 0%
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0
  }

  .md\:m-0 {
    margin: 0
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .md\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .md\:overflow-hidden {
    overflow: hidden
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-8 {
    padding: 0.8rem
  }

  .md\:p-16 {
    padding: 1.6rem
  }

  .md\:p-24 {
    padding: 2.4rem
  }

  .md\:p-48 {
    padding: 4.8rem
  }

  .md\:p-128 {
    padding: 12.8rem
  }

  .md\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .md\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  .md\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .md\:pl-0 {
    padding-left: 0
  }

  .md\:pb-32 {
    padding-bottom: 3.2rem
  }

  .md\:pb-64 {
    padding-bottom: 6.4rem
  }

  .md\:pt-128 {
    padding-top: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:pr-32,[dir='ltr'].md\:ltr\:pr-32 {
    padding-right: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:pl-32,[dir='rtl'].md\:rtl\:pl-32 {
    padding-left: 3.2rem
  }

  .md\:text-left {
    text-align: left
  }

  .md\:w-320 {
    width: 32rem
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-1\/3 {
    width: 33.333333%
  }

  .md\:w-1\/4 {
    width: 25%
  }

  .md\:w-3\/4 {
    width: 75%
  }

  .md\:w-2\/5 {
    width: 40%
  }

  .md\:w-3\/5 {
    width: 60%
  }

  .md\:w-full {
    width: 100%
  }
}

@media (min-width: 1280px) {

  .lg\:rounded-8 {
    border-radius: .8rem
  }

  .lg\:flex {
    display: flex
  }

  .lg\:hidden {
    display: none
  }

  .lg\:flex-row {
    flex-direction: row
  }

  .lg\:items-center {
    align-items: center
  }

  .lg\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .lg\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  .lg\:p-24 {
    padding: 2.4rem
  }

  .lg\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  .lg\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .lg\:pt-0 {
    padding-top: 0
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  [dir='ltr'] .lg\:ltr\:pl-0,[dir='ltr'].lg\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='ltr'] .lg\:ltr\:pr-4,[dir='ltr'].lg\:ltr\:pr-4 {
    padding-right: 0.4rem
  }

  [dir='ltr'] .lg\:ltr\:pl-16,[dir='ltr'].lg\:ltr\:pl-16 {
    padding-left: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:pr-0,[dir='rtl'].lg\:rtl\:pr-0 {
    padding-right: 0
  }

  [dir='rtl'] .lg\:rtl\:pl-4,[dir='rtl'].lg\:rtl\:pl-4 {
    padding-left: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:pr-16,[dir='rtl'].lg\:rtl\:pr-16 {
    padding-right: 1.6rem
  }

  .lg\:shadow-1 {
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)
  }

  .lg\:text-12 {
    font-size: 1.2rem
  }

  .lg\:text-16 {
    font-size: 1.6rem
  }

  .lg\:w-192 {
    width: 19.2rem
  }

  .lg\:w-1\/2 {
    width: 50%
  }

  .lg\:w-1\/3 {
    width: 33.333333%
  }

  .lg\:gap-16 {
    grid-gap: 1.6rem;
    gap: 1.6rem
  }
}

@media print {

  .print\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  .print\:mt-0 {
    margin-top: 0
  }

  .print\:mb-0 {
    margin-bottom: 0
  }

  .print\:mb-12 {
    margin-bottom: 1.2rem
  }

  .print\:p-0 {
    padding: 0
  }

  .print\:p-8 {
    padding: 0.8rem
  }

  .print\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .print\:shadow-none {
    box-shadow: none
  }

  .print\:text-16 {
    font-size: 1.6rem
  }

  .print\:w-60 {
    width: 6rem
  }

  .print\:w-full {
    width: 100%
  }
}